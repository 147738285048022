// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

.portfolio_block .portfolio_item .portfolio_content {
  padding: 9px 23px;

  h6 {
    margin: 0 0 11px 0;

    a {
      font-size: 22px;
      text-align: center;
      display: block;
      @media screen and (max-width: 760px) {
        line-height: 1.1em;
      }
    }
  }
}

@media only screen and (max-width: 760px) {
  .fw_content_wrapper {
    background: #f7f7f7 !important;
  }
}

.module_content {
  p, ul {
    margin: 0 0 10px 0;
  }

  p + ul {
    margin-top: -10px;
  }
}